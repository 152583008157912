export class WalletPointsInfo {
  redeemableWalletPoints: number
  walletUsageText: string

  constructor(args: {
    redeemableWalletPoints?: number,
    walletUsageText: string
  }) {
    this.redeemableWalletPoints = args.redeemableWalletPoints ?? 0,
    this.walletUsageText = args.walletUsageText
  }
}