import "firebase/auth";
import { useState } from "react";
import { auth } from "../../firebase";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";

declare global {
    interface Window { confirmationResult: any; }
}

window.confirmationResult = window.confirmationResult || {};

const SignIn = () => {
    const [errMsg, setErrMsg] = useState<string>("")
    const [otp, setOtp] = useState<string>("")
    const [confirmObj, setConfirmObj] = useState<any>("")
    const [phoneNumber, setPhoneNumber] = useState<string>("")
    const [viewOtpForm, setViewOtpForm] = useState<boolean>(false);
    const [displayBasic, setDisplayBasic] = useState<boolean>(false);

    function requestCaptcha(number: string) {
        const recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {},
            auth
        )
        recaptchaVerifier.render()
        return signInWithPhoneNumber(auth, number, recaptchaVerifier);
    }

    const getOTP = async (e: any) => {
        e.preventDefault();
        if (phoneNumber === "" || phoneNumber === undefined || phoneNumber.length != 10)
            return setErrMsg("Please enter a valid phone number");
        try {
            const response = await requestCaptcha("+91" + phoneNumber)
            setConfirmObj(response)
            setViewOtpForm(true)
        } catch (e: any) {
            setErrMsg(e.message)
        }

    };
    const verifyOtp = async () => {
        if (otp === "" || otp === null) return setErrMsg("Please enter the OTP");
        try {
            // const response = 
            await confirmObj.confirm(otp)
        } catch (e: any) {
            setErrMsg(e.message)
        }
    }


    return (
        <div className="text-white100 firebaseui-card-content"
        >
            <div className="firebaseui-list-item mx-auto"
                style={{ maxWidth: "328px", margin: "auto", marginBottom: "1rem", }}
                onClick={() => setDisplayBasic(true)}
            >
                <button
                    style={{ backgroundColor: "#02bd7e" }}
                    className="firebaseui-idp-button mdl-button mdl-js-button mdl-button--raised firebaseui-idp-phone firebaseui-id-idp-button"
                >

                    <span className="firebaseui-idp-icon-wrapper">
                        <img
                            className="firebaseui-idp-icon"
                            alt="Adfa"
                            src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/phone.svg" />
                    </span>
                    <span className="firebaseui-idp-text firebaseui-idp-text-10ng">Sign in with phone
                    </span>
                    <span className="firebaseui-idp-text firebaseui-idp-text-short">Phone</span>
                </button>
            </div>
            <Dialog header="Login with Phone Number" visible={displayBasic} style={{ width: '95vw' }}
                footer={<></>}
                onHide={() => setDisplayBasic(false)}>
                {errMsg && <p className="text-red100 text-sm">{errMsg}</p>}

                {!viewOtpForm ?
                    <div className="my-4">
                        <div id="loginDiv">
                            <InputText
                                className="text-black100 w-full"
                                type="text"
                                placeholder="Enter your 10 digit phone number"
                                value={phoneNumber}
                                onChange={e => setPhoneNumber(e.target.value)}
                            />
                            <div className="my-4">
                                <div id="recaptcha-container" className="my-2">
                                </div>
                                <Button
                                    className="p-buttonCustom"
                                    onClick={getOTP}
                                    label="Send OTP"
                                />
                            </div>
                        </div>

                    </div>

                    :
                    <div className="form-wrapper"
                    >
                        <div id="otpForm">
                            <div className="my-4">
                                <InputText
                                    placeholder="Enter 6 digit OTP"
                                    className="w-full"
                                    value={otp}
                                    onChange={e => setOtp(e.target.value)}
                                    autoComplete="false"
                                />
                            </div>
                            <Button
                                className="p-buttonCustom"
                                onClick={verifyOtp}
                                label="Verify OTP"
                            />
                        </div>
                    </div>
                }
            </Dialog>
        </div>
    );
};

export default SignIn;