import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { CartThunk } from '../../global/reduxThunk/cart';
// import { emptyCart } from '../../global/reduxSlices/cartSlices';
import { useSearchParams } from 'react-router-dom';
import { Cart } from '../../entities/Cart';
import { Money, CurrencyCodes } from '../../entities/Merchandise';
import { removeCustomer } from '../../global/reduxSlices/customerSlice';

type Props = {
    title: string,
    goBackTo: () => void
}
const CartHeader: React.FunctionComponent<Props> = ({ title, goBackTo }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        document.title = title
    }, [])
    const handlePreviousRoute = () => {
        if (searchParams.get("from"))
            navigate(searchParams.get("from") || '')
        else
            goBackTo()

    }
    const nItemsInCart = useAppSelector(state => {
        return {
          itemsCount: state.cart.lineItems
            .map((li) => li.quantity )
            .reduce((a, b) => {
                return a + b
            }, 0),
          cartId: state.cart.cartId
        };
      });
      
      
    return (
        <>
            <div className="grid grid-cols-3 justify-between items-center p-4 bg-darkBaseColor text-white100">
                <i className="pi pi-arrow-left"
                    onClick={handlePreviousRoute}
                ></i>
                <p className="font-semibold text-center">
                    Cart({nItemsInCart.itemsCount})
                </p>
                {
                    nItemsInCart.itemsCount > 0
                    &&
                    < span
                        onClick={async() => {
                            await dispatch(CartThunk.clearCart({ cartId: nItemsInCart.cartId }));
                            await dispatch(CartThunk.createCart())
                            await dispatch(removeCustomer())
                            navigate('/');
                        }}
                        className={`text-sm text-right cursor-pointer`}>CLEAR</span>
                }
            </div>
        </>
    )
}

export default CartHeader