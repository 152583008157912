import { auth } from "./firebase"

export const parseJwt = (token: any) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

export const getToken = async () => {
    let token: any;
    await new Promise(function (resolve, reject) {
        try {
            auth.onAuthStateChanged((user) => {
                if (user) {
                    user.getIdToken(true).then(idToken => {
                        resolve(idToken)
                    })
                }
            })
        } catch (error) {
            reject(error)
        }
    })
        .then(
            (result: any) => {
                token = result;
            },
            (error: any) => {
                throw error;
            }
        );
    return token;
}

export const returnAccessToken: any = async (token: any) => {
    if (!token) return { new_token: false, token, logout_action: true };

    const decodeJwt: any = parseJwt(token);
    const timeDiff = Date.now() - decodeJwt?.exp * 1000;
    console.log("Time Left To Expire In minutes: ", timeDiff / (60 * 1000));

    if (timeDiff < 0) {
        return { new_token: false, token, logout_action: false }
    } else if (timeDiff < 60 * 60 * 1000) {
        try {
            const newToken = await getToken()
            return { new_token: true, token: newToken, logout_action: false }
        } catch (error) {
            // Todo: Handle Error. Probably an ui on screen or something, which pop ups only in dev
            // return { new_token: false, token, logout_action: true };
            console.log(error);
        }
    }
    else return { new_token: false, token, logout_action: true };
}
