import { AuthDetailProps } from "../../types"
import { createSlice } from "@reduxjs/toolkit"
import { AuthThunk } from "../reduxThunk/auth";
import { AuthOptionISProps } from "../../types"

const initialState: AuthOptionISProps = {
  // token: "", 
  message: "",
  isLoading: false,
  isSuccess: false,
  isError: "",
  isTokenExp: false,
  expirationTime: 0,
  authDetail: {
    accessToken: "",
    displayName: "",
    email: "",
    phoneNumber: "",
    photoURL: "",
    customAttributes: { roles: [] },
    createdOn: ""
  },
  userRoles: {
    isVet: false,
    isDataAdmin: false,
    isAdmin: false,
    isHubManager: false,
    isProcurementHead: false,
    isQCHead: false,
    isFieldOfficer: false,
    isProcurementManager: false,
    isCST: false
  },
  preferredCity: {
    name: "Gurgaon",
    pin: 122001,
    state: "Haryana",
    nearestHub: "Gurgaon Hub",
    distance: 0,
    serviceDays:[],
    serviceType:''
  }
}




const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // changePrefferedCity:(state,action)=>{
    //   state.preferredCity={nearestname:action.payload.districtName,pin:action.payload.pincode,state:action.payload.stateName}
    // },
    refreshAuth: (state, action) => {
      state.authDetail.accessToken = action.payload.freshToken
    },
    addAuthDetails: (state, action) => {
      state.authDetail = action.payload;
      // state.token = action.payload.accessToken;
    },
    setAuthError: (state, action) => {
      state.isError = action.payload.message;
    },
    clearState: () => {
      return initialState
    },
    setRoles: (state, action) => {
      state.userRoles = action.payload;
    },
    updateProductSearch:(state, action)=>{
      const {shippingAddress}:any = action.payload;
      state.preferredCity = {
        name: shippingAddress.district,
        pin: shippingAddress.pin,
        state: shippingAddress.state,
        nearestHub: 'NA',
        distance: 0,
        serviceDays: [],
        serviceType: ''
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(AuthThunk.setAuthDetail.pending, (state) => {
        state.isLoading = true;
        state.isTokenExp = false;
      })
      .addCase(AuthThunk.setAuthDetail.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = "";
        state.expirationTime = action.payload.expirationTime as any;
        state.authDetail = action.payload as any;
        state.isTokenExp = false;
      })
      .addCase(AuthThunk.setAuthDetail.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = "Login Failed";
        state.message = action.payload as string;
        state.expirationTime = 0;
        state.authDetail = {} as AuthDetailProps;
        state.isTokenExp = false;
      })
      .addCase(AuthThunk.signOut.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(AuthThunk.signOut.fulfilled, () => {
        return initialState;
      })
      .addCase(AuthThunk.signOut.rejected, (state) => {
        state.isLoading = false;
      })
      // .addCase(AuthThunk.nearestHub.pending, (state) => {

      // })
      .addCase(AuthThunk.nearestHub.fulfilled, (state, action) => {
        const { payload: { pincode, stateName, districtName, nearestHub: { data: { warehouseDetails } } } } = action;
        state.isLoading = false;
        state.preferredCity = {
          name: districtName,
          pin: pincode,
          state: stateName,
          nearestHub: warehouseDetails.name || 'NA',
          distance: warehouseDetails?.distance ? Math.ceil(warehouseDetails.distance*1.25) : 0,
          serviceDays: warehouseDetails.serviceDays || [],
          serviceType: warehouseDetails.serviceType||''
        }
      })
      .addCase(AuthThunk.nearestHub.rejected, (state, payload) => {
        const { meta: { arg: { pincode, stateName, districtName } } } = payload;
        state.preferredCity = {
          name: districtName,
          pin: pincode,
          state: stateName,
          nearestHub: "NA",
          distance: 0,
          serviceDays:[],
          serviceType:''
        }
        state.isLoading = false;
      })
  },
})

export default authSlice.reducer;
export const {
  // changePrefferedCity,
  setRoles,
  clearState,
  refreshAuth,
  setAuthError,
  addAuthDetails,
  updateProductSearch,
} = authSlice.actions;
