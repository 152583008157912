import { Button } from 'primereact/button'

type Props = {
    message: string
}
const ErrorBox = ({ message }: Props) => {
    return (
        <div className="h-screen grid place-items-center w-screen">
            <div className="shadow p-4 m-4 rounded bg-[#f3eea6ee] "
                style={{ width: "calc(100vw - 2rem)" }}
            >
                <p className="mb-4 text-xl font-bold text-baseColor">Error</p>
                <p className="mb-2 break-words">{message}</p>
                <Button
                    onClick={() =>window.location.href="/"}
                    className="p-buttonCustomTextColor p-button-outlined p-button-danger m-2"
                    label="Redirect to Home"
                    />
            </div>
        </div>
    )
}

export default ErrorBox