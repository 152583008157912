import { Money } from "./Merchandise"
import { Order } from "./Order"
import { PaymentGatewayOrderInfo } from "./PaymentGatewayOrderInfo"

class Payment {
    _id: string

    order: Order

    amount: Money

    paymentMethod: string

    channel: string



    createdAt?: Date = new Date()


    reference?: string


    paymentGatewayInfo?: PaymentGatewayOrderInfo


    status?: string

    constructor(args: {
        _id: string,
        order: Order,
        amount: Money,
        paymentMethod: string,
        channel: string,
        createdAt?: Date,
        reference?: string,
        paymentGatewayInfo?: PaymentGatewayOrderInfo,
        status?: string
    }) {
        this._id = args._id
        this.order = args.order
        this.amount = args.amount
        this.paymentMethod = args.paymentMethod
        this.channel = args.channel
        this.createdAt = args.createdAt
        this.reference = args.reference
        this.paymentGatewayInfo = args.paymentGatewayInfo
        this.status = args.status
    }
}

export default Payment;
