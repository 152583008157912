export const getAuthDetail = (user: any) => {
    const { stsTokenManager, reloadUserInfo } = user || {};
    const { expirationTime } = stsTokenManager || {};
    const { customAttributes } = reloadUserInfo || {}
    const getVal = (key: any) => user && user[key] ? user[key] : "";
    const userDetail = {
        customAttributes: customAttributes ? JSON.parse(customAttributes) : { roles: [] },
        // ...getVal('reloadUserInfo'),
        email: getVal('email'),
        photoURL: getVal('photoURL'),
        accessToken: getVal('accessToken'),
        displayName: getVal('displayName'),
        phoneNumber: getVal('phoneNumber'),
        createdOn: user?.metadata?.creationTime || "",
        expirationTime: expirationTime ? expirationTime : 0
    }
    return userDetail
}

export const getIsUserRole = (roles: Array<any>, value: string) => (roles.includes(value))
export const getUserRoles = (user: any) => {
    const { customAttributes } = user || {};
    const { roles = [] } = customAttributes || {};
    const isSalesPerson = getIsUserRole(roles, "SalesPerson") || false;
    // const isAdmin = getIsUserRole(roles, "Admin") || false;
    // const isHubManager = getIsUserRole(roles, "HubManager") || false;
    // const isVet = getIsUserRole(roles, "Vet") || false;
    // const isProcurementHead = getIsUserRole(roles, "ProcurementHead") || false;
    // const isQCHead = getIsUserRole(roles, "QCHead") || false;
    // const isFieldOfficer = getIsUserRole(roles, "FieldOfficer") || false;
    // const isProcurementManager = getIsUserRole(roles, "ProcurementManager") || false;
    // const isCST = getIsUserRole(roles, "CS") || false;
    return { isSalesPerson }
}