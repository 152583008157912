import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BACKEND_BASEURL });
import { setContext } from "@apollo/client/link/context";

import { auth } from "../firebase";

import { parseJwt } from "../authHelper";


const authLink = setContext( async (_ : any,{headers} : any) => {
  let token = await auth.currentUser?.getIdToken(false)
  const decodedToken = parseJwt(token)
  const tenMins = 600000
  const timeDiff = (Date.now() + tenMins) - decodedToken?.exp * 1000
  if(timeDiff > 0) {
    token = await auth.currentUser?.getIdToken(true)
  }
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
  }
}})


export const client = new ApolloClient({
  link: from([authLink, httpLink]),
  cache: new InMemoryCache({
    resultCaching : false
  }),
});
