import { Fragment } from 'react'
import { Button } from 'primereact/button'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
        <Fragment>
            <h1 className="text-6xl font-bold text-baseColor">404</h1>
            <p className="text-3xl">Page not found.</p>
            <p className="my-4">We're sorry, the page you requested could not be found. Please go back to the homepage!</p>
            <Button
                label="Go Home"
                className="p-button-outlined p-button-danger p-buttonCustomTextColor"
                onClick={() => navigate(-1)}
            />
        </Fragment>
    )
}

export default PageNotFound