import SalesPerson from "./SalesPerson";

class SalesChannel {
  salesApplication: string;
  salesPerson?: SalesPerson; 

  constructor(salesApplication: string, salesPerson?: SalesPerson) {
    this.salesApplication = salesApplication;
    this.salesPerson = salesPerson;
  }
}

export default SalesChannel;
