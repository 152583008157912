import React from "react"
import { ROUTE_ROLE_PERMISSION } from "./constants"

const AddPoints = React.lazy(() => import("../pages/customer/AddPoints"))
const AllAddresses = React.lazy(() => import("../pages/customer/AllAddresses"))
const BillTotal = React.lazy(() => import("../pages/bills/Total"))
const Cart = React.lazy(() => import("../pages/bills/Cart"))
const CheckForDelivery = React.lazy(() => import("../pages/CheckForDelivery"))
const Coupons = React.lazy(() => import("../pages/feed/Coupons"))
const Dashboard = React.lazy(() => import("../pages/feed/Dashboard"))
const DashboardBhains = React.lazy(() => import("../pages/DashboardBhains"))
const EditAddress = React.lazy(() => import("../pages/customer/EditAddress"))
const EditCustomerContactInfo = React.lazy(() => import("../pages/customer/EditContactInformation"))
const EditAdditionalContactInformation = React.lazy(()=> import("../pages/customer/EditAdditionalContactInformation"))
const OrderConfirmation = React.lazy(() => import("../pages/bills/OrderConfirmation"))
const ReferralHistory = React.lazy(() => import("../pages/customer/ReferralHistory"))
const SearchCustomer = React.lazy(() => import("../pages/SearchCustomer"))
const SearchOrder = React.lazy(() => import("../pages/SearchOrder"))
const SalesPersonOrderListing = React.lazy(() => import("../pages/salesperson/OrderListing"))
const SalesPersonOrderSummary = React.lazy(() => import("../pages/salesperson/OrderSummary"))
const SalesPersonInsights = React.lazy(() => import("../pages/salesperson/Insights"))
const SalesYourCustomers = React.lazy(() => import("../pages/salesperson/YourCustomers"))
const CustomerOrderHistory = React.lazy(() => import("../pages/customer/OrderHistory"))
const Total = React.lazy(()=> import("../pages/bills/Total"))

export const routesList = {
    addPoints: "/addpoints",
    billTotal: "/billTotal",
    cart: "/cart",
    total: "/payment",
    dashboard: '/',
    dashboardb: "/b",
    customerAddress: "/customerAddress",
    editCustomerAddress: "/customerAddress/change",
    editCustomerContactInfo: "/customerContact/change",
    editAdditionalCustomerContactInfo:"/customerDetails/AdditionalInfo/change",
    orderConfirmation: "/orderConfirmation",
    checkForDelivery: "/checkForDelivery",
    customerOrderHistory: "/customerOrderHistory",
    salesPersonOrderListing: "/salesperson/orderlisting",
    salesPersonOrderSummary: "/salesperson/orderstatus",
    salesPersonInsights: "/salesperson/insights",
    salesYourCustomer: "/salesperson/yourcustomers",
    searchCustomer: '/searchcustomer',
    referralHistory: "/referralhistory",
    searchOrder: '/searchorder',
}

const MainRouteList = [
    { path: routesList.dashboard, exact: true, element: Dashboard, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.dashboardb, exact: true, element: DashboardBhains, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.cart, exact: true, element: Cart, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.total, exact: true, element: Total, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.searchCustomer, exact: true, element: SearchCustomer, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.customerAddress, exact: true, element: AllAddresses, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.editCustomerAddress, exact: true, element: EditAddress, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.billTotal, exact: true, element: BillTotal, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.orderConfirmation, exact: true, element: OrderConfirmation, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.checkForDelivery, exact: true, element: CheckForDelivery, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.salesPersonOrderListing, exact: true, element: SalesPersonOrderListing, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.salesPersonOrderSummary, exact: true, element: SalesPersonOrderSummary, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.salesPersonInsights, exact: true, element: SalesPersonInsights, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.salesYourCustomer, exact: true, element: SalesYourCustomers, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.addPoints, exact: true, element: AddPoints, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.referralHistory, exact: true, element: ReferralHistory, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.editCustomerContactInfo, exact: true, element: EditCustomerContactInfo, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.editAdditionalCustomerContactInfo, exact: true, element: EditAdditionalContactInformation, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.customerOrderHistory, exact: true, element: CustomerOrderHistory, roles: ROUTE_ROLE_PERMISSION.Salesperson },
    { path: routesList.searchOrder, exact: true, element: SearchOrder, roles: ROUTE_ROLE_PERMISSION.Salesperson },
]

export default MainRouteList