import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { persistor, store } from "./app/store"
import { Provider } from "react-redux"
import { ApolloProvider } from "@apollo/client"
import { client } from "./gqlOperations/graphql";
import { PersistGate } from 'redux-persist/integration/react';

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';


ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serv..stration.register()
// serviceWorkerRegistration.register();


