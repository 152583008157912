import { auth } from "../../firebase";
import { createAsyncThunk } from "@reduxjs/toolkit"
import { setRoles } from "../reduxSlices/authSlice";
import { getAuthDetail, getUserRoles } from "../reduxHelper/auth";
import { client } from "../../gqlOperations/graphql";
import { NEAREST_HUB } from "../../gqlOperations/queries";

const setAuthDetail = createAsyncThunk(
    'auth/set_user_detail',
    async (data: any, thunkAPI) => {
        thunkAPI.dispatch(setRoles(getUserRoles(data)));
        return data;
    }
);

const loginAuthDetail = createAsyncThunk(
    'auth/get_user_detail',
    async (_, thunkAPI) => {
        auth.onAuthStateChanged((user: any) => {
            thunkAPI.dispatch(setAuthDetail(getAuthDetail(user)));
            localStorage.setItem("token",user?.accessToken || '')
        })
    }
);

const signOut = createAsyncThunk(
    'auth/set_user_sign_out',
    async () => {
        auth.signOut();
        localStorage.clear();
        console.log(localStorage)
       
    }
);

const nearestHub=createAsyncThunk(
    'auth/nearest_hub',
    async (payload: any) => {
        const nearestWH = await client.query({
            query: NEAREST_HUB,
            variables: { pincode: payload.pincode.toString() },
            fetchPolicy : 'network-only'
        });
       
        return {...payload,nearestHub:nearestWH}
    }
)

export const AuthThunk = {
    setAuthDetail,
    loginAuthDetail,
    signOut,
    nearestHub
};