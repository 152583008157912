export class Customer{
    userId : string
    fullName : string
    phoneNumber : string
    customerBusinessType : string

    static getEmptyCustomer() : Customer{
        const result = new Customer()
        result.userId = ''
        result.fullName = ''
        result.phoneNumber = ''
        result.customerBusinessType = 'FARMER'

        return result
    }
}