export class Address {
    id: string 
  
    contactPersons: ContactPerson[];
  
   
    firstLine: string;
  
 
    secondLine: string;
  

    landMark: string;
  
  
    city: string;
  
  
    district: string;
  

    state: string;
  

    pin: string;
  
    country: string;


    static getEmptyAddress() : Address{
      const result = new Address()
      result.id = ''
      result.contactPersons = []
      result.firstLine = ''
      result.secondLine = ''
      result.landMark = ''
      result.city = ''
      result.district = ''
      result.state = ''
      result.pin = ''
      result.country = ''

      return result
    }

    static fromTempData(data : any) : Address{
      if (!data){
        return data
      }
      const result = new Address()
      result.id = data.id
      result.contactPersons = data.contactPersons?.map((cp : any) => {
        const result = new ContactPerson()
        result.fullName = cp.fullName
        result.nickName = cp.nickName
        result.phoneNumber = cp.phoneNumber
        result.relation = cp.relation
        return result
      })
      result.firstLine = data.firstLine
      result.secondLine = data.secondLine
      result.landMark = data.landMark
      result.city = data.city
      result.district = data.district
      result.state = data.state
      result.pin = data.pin
      result.country = data.country

      return result

    }

  }

  export class ContactPerson {
    fullName: string;
    nickName?: string;
    phoneNumber: string;
    relation: string;
  }

  //TODO    address id is null