import "./assets/css/App.css";
import { useEffect } from "react";
import "primeicons/primeicons.css";
import './firebaseui-styling.global.css';
import AllRoutes from './routes/AllRoutes';
import { returnAccessToken } from "./authHelper";
import "primereact/resources/primereact.min.css";
import { AuthThunk } from "./global/reduxThunk/auth";
import { CartThunk } from "./global/reduxThunk/cart";
import ErrorBox from "./components/globalAssets/ErrorBox";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import DefaultLoader from "./components/loaders/DefaultLoader";
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import { refreshAuth } from "./global/reduxSlices/authSlice";
import { auth } from "./firebase";
import { updateCartId } from "./global/reduxSlices/cartSlices";

function App() {
  const dispatch = useAppDispatch()
  const { isError, isLoading } = useAppSelector(state => state.auth)
  const { cartId } = useAppSelector(state => state.cart)
  useEffect(() => {
    (async () => {
      const { token, new_token, logout_action } = await returnAccessToken(await auth.currentUser?.getIdToken())
      if (window.location.pathname !== "/login") {
        if (logout_action) {
          dispatch(CartThunk.clearCart({cartId}))
          dispatch(AuthThunk.signOut())
          window.location.pathname = "/login";
        }
        else if (new_token) {
          localStorage.setItem("token", token);
          dispatch(refreshAuth({ freshToken: token }))
          window.location.reload()
        }
        
      }
      dispatch(AuthThunk.loginAuthDetail())
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>

      {isError && <ErrorBox message={isError} />}
      {isLoading && <DefaultLoader />}
      <AllRoutes />
    </>

  );
}

export default App;
