import { createSlice } from "@reduxjs/toolkit"
import { Action } from "../../types"

export enum PaymentMethods{
    COD = 'Cash on Delivery',
    PAYMENT_LINK = 'Payment Link'
}
type billOptionISProps = {
    coupon: string | null,
    tax: number,
    netDeliveryCharge: number,
    grossDeliveryCharge: number,
    paymentMethod: PaymentMethods,
    allowedPaymentMethods: [],
}

const initialState: billOptionISProps = {
    coupon: null,
    tax: 0,
    netDeliveryCharge: 0,
    grossDeliveryCharge: 0,
    paymentMethod: PaymentMethods.COD,
    allowedPaymentMethods: [],
}

const billSlice = createSlice({
    name: 'bill',
    initialState,
    reducers: {
        setBillCoupon: (state) => {
            return state
            // return {coupon:action.payload.coupon,...state}
        },
        setBillMethod: (state, action: Action) => {
            return { ...state, paymentMethod: action.payload.billMethod }
        },
        setDeliveryCharges: (state, action: Action) => {
            const { payload } = action;
            return { ...state, netDeliveryCharge: payload.netDeliveryCharge, grossDeliveryCharge: payload.grossDeliveryCharge }
        },
        setAllowedPaymentMethod: (state, action: Action) => {
            return { ...state, allowedPaymentMethods: action.payload.allowedPaymentMethods }
        },
    }
})

export default billSlice.reducer;
export const { setBillCoupon,
    setBillMethod,
    setDeliveryCharges, setAllowedPaymentMethod } = billSlice.actions