/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from 'react';
import { auth } from "../firebase";
import { Image } from 'primereact/image';
import { useAppSelector } from '../app/hooks';
import { useNavigate } from 'react-router-dom';
import LoginLogo from "../assets/images/Vector.png";
import LoginLogoText from "../assets/images/Group 35500.png";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { EmailAuthProvider, GoogleAuthProvider } from "@firebase/auth";
import PhoneOtpLogin from '../components/dialogs/PhoneOtpLogin';
import { returnAccessToken } from '../authHelper';

const Login = () => {

  const uiConfig = {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      EmailAuthProvider.PROVIDER_ID,
    ],
    callbacks: {  // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => true
    },
  };
  const navigate = useNavigate()
  const { token, isLoading } = useAppSelector((state: any) => state.auth);
  useEffect(() => {
    (async () => {
      const { logout_action } = await returnAccessToken(await auth.currentUser?.getIdToken())
      if (!logout_action)
        navigate("/")
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, token])

  return (
    <div
      className="h-screen grid place-items-center relative bg-darkBaseColor"
    >
      <div className="grid place-items-center">
        <Image
          src={LoginLogo}
          alt="LOGO LOGIN"
        />
        <Image
          className="mt-4"
          src={LoginLogoText}
          alt="LOGO LOGIN TEXT"
        />
      </div>

      <div
        className="absolute bottom-4 left-4 right-4"
      >
        {
          uiConfig
          &&
          <StyledFirebaseAuth
            uiCallback={ui => ui.disableAutoSignIn()}
            uiConfig={uiConfig}
            firebaseAuth={auth} />
        }
        <PhoneOtpLogin />
      </div>
    </div>
  )
}

export default Login