import { createAsyncThunk } from "@reduxjs/toolkit";
import { GET_CUSTOMER_OFFERS } from "../../gqlOperations/queries";
import { client } from "../../gqlOperations/graphql";


export const fetchOffers = createAsyncThunk(
    "offers/fetchOffers",
    async (payload: { userId: string, orderTotal:number, customerBusinessType:string}, { getState }) => {
      try {
        const state: any = getState();
        const result = await client.query({
          query: GET_CUSTOMER_OFFERS,
          variables: { 
            userId: payload.userId,
            orderTotal:payload.orderTotal,
            customerBusinessType:payload.customerBusinessType 
        },
          fetchPolicy: "no-cache",
        });
        return result.data.myOffers;
      } catch (e) {
        console.error(e);
        throw e;
      }
    }
  );

  export const OfferThunk ={
    fetchOffers
  };