export class Merchandise {
    category: ProductType;
    title: string;
    variantTitle: string;
    id: string;
    price: Money;
    mrp: Money;
    sku: string;
    weight: Quantity;
  }

  export enum ProductType {
    FEED = 'FEED',
    ANIMAL = 'ANIMAL',
    ACCESSORIES = 'ACCESSORIES'
  }

  export class Money {
    currencyCode: CurrencyCodes;
    amount: number;
  
    constructor(args: { amount: number; currencyCode: CurrencyCodes }) {
      this.amount = args.amount;
      this.currencyCode = args.currencyCode;
    }
  }

  export enum CurrencyCodes {
    INR = 'INR',
  }

  export class Quantity {
    value: number
    uom: UOM
  }
  
  export enum UOM {
    KG = 'kg',
  }