import { createSlice } from "@reduxjs/toolkit";
import { getCustomerDetail } from "../reduxThunk/customer";
import { Action, ICustomerDetailsProps } from "../../types";


const initialState: ICustomerDetailsProps = {
  customerDetails: null,
  customerWallet: null,
  redeemablePoints:null,
  defaultShippingAddress: "",
  loading: false,
  error: ""
}

const customerSlice = createSlice({
  name: 'customerDetails',
  initialState,
  reducers: {
    removeCustomer: () => {
      return initialState;
    },
    setShippingAddress: (state, action: Action) => {
      return { ...state, defaultShippingAddress: action.payload.id }
    },
    setCustomerWallet: (state, action: Action) => {
      return { ...state, customerWallet: action.payload }
    },
    setCustomerRedeemablePoints: (state, action: Action) => {
      return { ...state, redeemablePoints: action.payload }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerDetail.pending, (state) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(getCustomerDetail.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.error = "";
        state.customerDetails = payload.data as any;
        state.defaultShippingAddress = payload.data.customer?.shippingAddresses[0]?.id
      })
      .addCase(getCustomerDetail.rejected, (state) => {
        state.loading = false;
        state.customerDetails = null;
        state.error = "No record found for the provided number";
      })
  }
})

export default customerSlice.reducer;
export const { 
  removeCustomer,
  setShippingAddress,
  setCustomerWallet,
  setCustomerRedeemablePoints 
} = customerSlice.actions;