import { createAsyncThunk } from "@reduxjs/toolkit";
import { SEARCH_A_CUSTOMER } from "../../gqlOperations/queries";
import { client } from "../../gqlOperations/graphql";

export const getCustomerDetail = createAsyncThunk(
    'customer/get_details',
    async (payload: any) => {
        return client.query({
            query: SEARCH_A_CUSTOMER,
            variables: { phoneNumber: payload.phoneNumber },
            fetchPolicy : 'network-only'
        })
    }
)