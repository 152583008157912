
export class ShipmentStatusUpdate {

    title ?: string

    date ?: Date

    description ?: string


    statusType ?: string
  
    constructor(args : {
      title ?: string,
      date ?: Date,
      description ?: string,
      statusType ?: string
    }) {
  
      this.title = args.title
      this.date = args.date
      this.description = args.description
      this.statusType = args.statusType
    }
}
  
