import { Address } from "./Address";
import { CartOffer } from "./CartOffer";
import { Customer } from "./Customer";
import { Discount } from "./Discount";
import { LineItem } from "./LineItem";
import { CurrencyCodes, Money } from "./Merchandise";
import { WalletPointsInfo } from "./WalletPointsInfo";

export class Cart{
    serviceable = true
    isError : boolean
    estimatedDeliveryDate  :Date

    cartId : string

    isLoading : boolean

    lineItems : LineItem[]
    netTotal : Money
    subTotal : Money

    customer : Customer

    discounts : Discount[]

    shippingAddress : Address
    grossShippingCharge : Money
    netShippingCharge : Money
    
    availableOffers: CartOffer[]

    appliedOffers: CartOffer[]

    walletPointsInfo: WalletPointsInfo

    
    static fromBffResponse(payload : any) : Cart{
        const result = new Cart()
        result.lineItems = payload.lineItems
        result.netTotal = payload.netTotal
        result.subTotal = payload.subTotal
        result.shippingAddress = Address.fromTempData(payload.shippingAddress)
        const customer = new Customer()
        customer.userId = payload.userId
        customer.customerBusinessType = payload.customerBusinessType
        result.customer = customer
        result.cartId = payload.cartId 
        result.discounts = payload.cartDiscounts || []
        result.estimatedDeliveryDate = payload.estimatedDeliveryDate
        result.grossShippingCharge = payload.grossShippingCharge
        result.netShippingCharge = payload.netShippingCharge
        result.availableOffers = payload.availableOffers || [];
        result.appliedOffers = payload.appliedOffers || []
        result.walletPointsInfo = new WalletPointsInfo({
          redeemableWalletPoints: payload.walletPointsInfo.redeemableWalletPoints,
          walletUsageText: payload.walletPointsInfo.walletUsageText
        })
        return result
    }

    static getEmptyCart = () : Cart => {
        const result = new Cart()
        result.serviceable = true,
        result.isError = false
        result.estimatedDeliveryDate = new Date()
        result.cartId = '',
        result.isLoading = false
        result.lineItems = []
        result.netTotal = new Money({
            currencyCode : CurrencyCodes.INR,
            amount : 0
        })
        result.subTotal = new Money({
            currencyCode : CurrencyCodes.INR,
            amount : 0
        })
        result.grossShippingCharge = new Money({
            currencyCode : CurrencyCodes.INR,
            amount : 0
        })
        result.netShippingCharge = new Money({
            currencyCode : CurrencyCodes.INR,
            amount : 0
        })
        result.customer = Customer.getEmptyCustomer()
        result.discounts = []
        result.shippingAddress = Address.getEmptyAddress()
        result.walletPointsInfo = new WalletPointsInfo({
          redeemableWalletPoints: 0,
          walletUsageText: ''
        })
        return result
    }
}


