import { createSlice } from '@reduxjs/toolkit';
import forEach from 'lodash/forEach';
import filter from 'lodash/filter';

import { CartThunk } from '../reduxThunk/cart';
import * as types from '../../types';
import { Cart } from '../../entities/Cart';

const initialState : Cart = Cart.getEmptyCart()

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addDiscountedItemsToCart: (state, action: types.Action) => {
      // return {...state}
    },
    removeFromCart: (state, action: types.Action) => {
      // return {...state}
    },
    incrementUnitsInCart: (state, action: types.Action) => {
      // return {...state}
    },
    addCertainUnitsInCart: (state, action: types.Action) => {
      // return {...state}
    },
    updateCartReducer: (state, { payload }) => {
      return {
        ...state,
        ...payload
      };
    },
    updateCustomerInCartReducer: (state, { payload }) => {
      // return {...state}
    },
    updateShippingAddressInCartReducer: (state, { payload }) => {
      // return {...state}
    },
    updateCartItemReducer: (state, { payload }) => {
      // return {...state}
    },
    updateCartId: (state, { payload }) => {
      console.log('update cart sync reducer')
    },
    updateCartWalletPointsReducer: (state, {payload}) => {
      return {
        ...state,
        ...payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(CartThunk.createCart.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        newState.isError = false
        return newState
      })
      .addCase(CartThunk.createCart.fulfilled, (state, { payload }: any) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = false;
        newState.cartId = payload?.data?.createCart?.cartId;
        return newState

      })
      .addCase(CartThunk.createCart.rejected, (state, action) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState

      })
      .addCase(CartThunk.clearCart.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        return newState
        

      })
      .addCase(CartThunk.clearCart.fulfilled, (state) => {
        const newState = initialState
        console.log('Clear cart pending')
        newState.isLoading = false;
        newState.isError = false;
        newState.lineItems = [];
        newState.cartId = ''
        return newState
        
        
      })
      .addCase(CartThunk.clearCart.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.updateLineItem.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        return newState
      })
      .addCase(CartThunk.updateLineItem.fulfilled, (state, { payload }: any) => {
        let newState = {...state}
        newState = Cart.fromBffResponse(payload.data.updateCartItem)
        newState.isLoading = false;
        newState.isError = false;
        
        const h = 1
        return newState
      })
      .addCase(CartThunk.updateLineItem.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.addToCart.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        return newState
      })
      .addCase(CartThunk.addToCart.fulfilled, (state, { payload }: any) => {
        console.log('cart update li fulfilled')
        const newState = Cart.fromBffResponse(payload.data.updateCartItem)
        newState.isLoading = false;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.addToCart.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.calculateWalletPointsAndUpdateOffers.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        return newState
      })
      .addCase(CartThunk.calculateWalletPointsAndUpdateOffers.fulfilled, (state, { payload }: any) => {
        const newState = Cart.fromBffResponse(payload.data.calculateWalletPointsAndUpdateOffers)
        newState.isLoading = false;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.calculateWalletPointsAndUpdateOffers.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.loadCart.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.loadCart.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.loadCart.fulfilled, (state, { payload }: any) => {
        console.log('cart load fulfilled')
        const newState = Cart.fromBffResponse(payload.data.cartDetails)
        newState.isLoading = false;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.addCustomerAndShippingAddressToCart.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.addCustomerAndShippingAddressToCart.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.addCustomerAndShippingAddressToCart.fulfilled, (state, { payload }: any) => {
        console.log('cart add to customer fulfilled')
        const newState = Cart.fromBffResponse(payload.data.updateShippingAddressInCart)
        newState.isLoading = false;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.updateShippingAddress.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.updateShippingAddress.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.updateShippingAddress.fulfilled, (state, { payload }: any) => {
        console.log('cart add to customer fulfilled')
        const newState = Cart.fromBffResponse(payload.data.updateShippingAddressInCart)
        newState.isLoading = false;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.checkServiceability.rejected, (state) => {
        const newState = {...state}
        newState.isLoading = false;
        newState.isError = true;
        return newState
      })
      .addCase(CartThunk.checkServiceability.pending, (state) => {
        const newState = {...state}
        newState.isLoading = true;
        newState.isError = false;
        return newState
      })
      .addCase(CartThunk.checkServiceability.fulfilled, (state, { payload }: any) => {
        console.log('cart check serviceability fulfilled')
        const newState = {...state}
        newState.serviceable = payload.data.checkServiceability
        newState.isLoading = false;
        newState.isError = false;
        return newState
      })
  },
});

export default cartSlice.reducer;
export const {
  addDiscountedItemsToCart,
  removeFromCart,
  incrementUnitsInCart,
  addCertainUnitsInCart,
  updateCartReducer,
  updateCustomerInCartReducer,
  updateShippingAddressInCartReducer,
  updateCartItemReducer,
  updateCartId
} = cartSlice.actions;
