import { WhiteNavbarProps } from '../../types';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const WhiteNavbar = ({ title, icons = "arrow-left", goBackTo, shadow = true }: WhiteNavbarProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  useEffect(() => {
    document.title = title;
  })
  
  const handlePreviousRoute = () => {
    if (searchParams.get("from")) {
      const path = searchParams.get("from")
      let  navigationData = {}
      if(path == '/orderConfirmation') {
        let orderIds = JSON.parse(searchParams.get('orderIds') || '')
        orderIds = orderIds.map((e: string) => {
          return {
            _id: e
          }
        })
        navigationData = {
          state: {
            orderIds: orderIds
          }
        }
      }
      navigate(path || '', navigationData) 
    }
    else {
      goBackTo()
    }

  }
  return (
    <div className={` bg-white100 ${shadow && `drop-shadow`} p-4 relative`}>
      <i
        onClick={handlePreviousRoute}
        className={`pi pi-${icons} absolute top-2/4 left-4 -translate-y-2/4`}></i>
      <p className="text-center textWrap1">{title}</p>
    </div>
  )
}

export default WhiteNavbar