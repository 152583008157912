import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import isEmpty from "lodash/isEmpty"
import { parseJwt } from '../authHelper';

const CheckAuth = ({ children }: any) => {


  const { customer } = useAppSelector(state => state)
  const location = useLocation();

  const decodeJwt: any = parseJwt(localStorage.getItem("token"));
  const timeDiff = Date.now() - decodeJwt?.exp * 1000;
  
  if (["/customeraddress", "/customeraddress/change"].includes(location.pathname) && isEmpty(customer.customerDetails))
    return <Navigate to="/searchcustomer" replace={true} state={{ from: location }} />
  if (timeDiff < 60 * 60 * 1000)
    return children;

  return <Navigate to="/login" replace={true} state={{ from: location }} />
}

export default CheckAuth