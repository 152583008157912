class SalesPerson {
    uid?: string; 
    displayName?: string; 
  
    constructor(uid?: string, displayName?: string) {
      this.uid = uid;
      this.displayName = displayName;
    }
  }
  
  export default SalesPerson;
  