// import { User } from "firebase/auth";
import { Address } from "./Address";
import { Customer } from "./Customer";
import { Discount } from "./Discount";
import { LineItem } from "./LineItem";
import { CurrencyCodes, Money } from "./Merchandise";
import { OrderStatus } from "./OrderStatus";
import Payment from "./Payment";
import { PaymentGatewayOrderInfo } from "./PaymentGatewayOrderInfo";
import { Shipment } from "./Shipment";
import SalesChannel from "./SalesChannel";
import { PaymentMethod } from "./PaymentMethod";
import { ShipmentStatusUpdate } from "./ShipmentStatusUpdate";
import SalesPerson from "./SalesPerson";
import User from "./User";


export class Order {
  salesChannelInfo: SalesChannel;
  testOrder : false;
  _id?: string;
  deliveryStatus: string;
  orderNumber: string;
  referenceNumber?: string;
  paymentGatewayInfo?: PaymentGatewayOrderInfo;
  status: OrderStatus;
  customer?: User;
  lineItems: LineItem[];
  shippingAddress: Address;
  billingAddress?: Address;
  netTotal: Money;
  paidAmount: Money;
  netPayable: Money;
  payments: Payment[];
  selectedPaymentMethod?: string;
  discounts: Discount[];
  orderDate: Date = new Date();
  shipments: Shipment[];
  zohoSalesOrderId?: string;
  zohoSalesOrderNumber?: string;
  estimatedDeliveryDate?: Date;
  grossShippingCharge?: Money;
  netShippingCharge?: Money;
  allowedPaymentMethods?: PaymentMethod[];
  paymentLink?: string;



  static fromBffResponse(orderResponseBody: any): Order {
    const orderInfo = orderResponseBody;

    const _payments = orderInfo.payments.map((payment: { _id: any; order: any; amount: any; paymentMethod: any; reference: any; channel: any; createdAt: string | number | Date; paymentGatewayInfo: any; status: any; }) => {
      return new Payment({
        _id: payment._id,
        order: payment.order,
        amount: payment.amount,
        paymentMethod: payment.paymentMethod,
        reference: payment.reference || '',
        channel: payment.channel,
        createdAt: new Date(payment.createdAt),
        paymentGatewayInfo:
          payment.paymentGatewayInfo || new PaymentGatewayOrderInfo(),
        status: payment.status,
      });
    });

    const _shipments = orderInfo.shipments.map((shipment: { statusUpdates: any[]; _id: any; estimatedDeliveryDate: string | number | Date; lineItems: any; status: any; }) => {
      const _statusUpdates = shipment.statusUpdates.map((statusUpdate) => {
        return new ShipmentStatusUpdate({
          title: statusUpdate.title,
          date: new Date(statusUpdate.date),
          description: statusUpdate.description,
          statusType: statusUpdate.statusType,
        });
      });

      return new Shipment({
        id: shipment._id || '',
        estimatedDeliveryDate: orderInfo.estimatedDeliveryDate
        ? new Date(orderInfo.estimatedDeliveryDate)
        : new Date(),      
        statusUpdates: _statusUpdates,
        lineItems: shipment.lineItems,
        shipmentStatus: shipment.status,
      });
    });

    let orderNo;
    if (!orderInfo.zohoSalesOrderNumber){
      orderNo = orderInfo.orderNumber
    }
    else{
      orderNo = orderInfo.zohoSalesOrderNumber
    }


    const paymentMethods = orderInfo?.allowedPaymentMethods?.map((method: any) =>{
      return new PaymentMethod({
        method: method.method
      })
    })

    const order = new Order();
    order._id = orderInfo._id;
    order.deliveryStatus = orderInfo.deliveryStatus;
    order.testOrder = orderInfo.testOrder;
    order.orderNumber = orderNo;
    order.status = orderInfo.status;
    order.paymentLink = orderInfo.paymentLink;
    order.referenceNumber = orderInfo.referenceNumber;
    order.paymentGatewayInfo = orderInfo.paymentGatewayInfo;
    order.customer = orderInfo.customer;
    order.lineItems = orderInfo.lineItems;
    order.shippingAddress = orderInfo.shippingAddress;
    order.billingAddress = orderInfo.billingAddress;
    order.netTotal = new Money({
        currencyCode: CurrencyCodes.INR,
        amount: orderInfo.netTotal.amount,
      });
      
      order.paidAmount = new Money({
        currencyCode: CurrencyCodes.INR,
        amount: orderInfo.paidAmount.amount,
      });
      
      order.netPayable = new Money({
        currencyCode: CurrencyCodes.INR,
        amount: orderInfo.netPayable.amount,
      });
      
    order.payments = _payments;
    order.selectedPaymentMethod = orderInfo.selectedPaymentMethod;
    order.discounts = orderInfo.discounts;
    order.orderDate = new Date(orderInfo.orderDate);
    order.shipments = _shipments;
    order.zohoSalesOrderId = orderInfo.zohoSalesOrderId;
    order.zohoSalesOrderNumber = orderInfo.zohoSalesOrderNumber;
    order.estimatedDeliveryDate = new Date(orderInfo.estimatedDeliveryDate);
    order.grossShippingCharge = new Money({
      currencyCode: CurrencyCodes.INR,
      amount: orderInfo.grossShippingCharge.amount,
    });
    order.netShippingCharge = new Money({
        currencyCode: CurrencyCodes.INR,
        amount: orderInfo.netShippingCharge.amount ,
      });
          order.allowedPaymentMethods = paymentMethods
    order.salesChannelInfo = (orderInfo.salesChannelInfo?.channelName === 'customer-success-team') ?
      new SalesChannel(
        orderInfo.salesChannelInfo.channelName,
        new SalesPerson(
          orderInfo.salesChannelInfo.salesPerson?.userId,
          orderInfo.salesChannelInfo.salesPerson?.displayName
        )
      ) :
      new SalesChannel(
        orderInfo.salesChannelInfo?.channelName,
      )

    return order;
  }
}