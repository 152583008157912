import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchOffers } from "../reduxThunk/offer";

interface Offer {
    id: string;
    code: string;
    name: string;
    description: string;
    discountAmount: number;
    validFrom: string;
    validTill: string;
    isApplicable: boolean;
  }
  
  interface OffersState {
    offers: Offer[];
    loading: boolean;
    error: string | null;
  }
  
  const initialState: OffersState = {
    offers: [],
    loading: false,
    error: null,
  };
  
  const offersSlice = createSlice({
    name: 'offers',
    initialState,
    reducers: {
      updateOffers: (state, action: PayloadAction<Offer[]>) => {
        state.offers = action.payload;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchOffers.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchOffers.fulfilled, (state, action: PayloadAction<Offer[]>) => {
          state.loading = false;
          state.offers = action.payload;
        })
        .addCase(fetchOffers.rejected, (state, action) => {
          state.loading = false;
          state.error = "";
        });
    },
  });
  
  export const { updateOffers } = offersSlice.actions;
  export default offersSlice.reducer;