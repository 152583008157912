import _ from 'lodash';
import { Image } from 'primereact/image';
import { Sidebar } from 'primereact/sidebar';
import { useNavigate } from 'react-router-dom';
import WhiteNavbar from '../navbar/WhiteNavbar';
import { useSearchParams } from "react-router-dom";
import { AuthThunk } from '../../global/reduxThunk/auth';
import { SideBarMenuLinks } from '../../global/constants';
import defaultAvatar from "../../assets/images/default.png"
import React, { useState, Fragment, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';

const DefaultSidebar: React.FC = () => {
    const [visibleLeft, setVisibleLeft] = useState(false);
    const { authDetail } = useAppSelector(state => state.auth)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();

    useEffect(() => {
        searchParams.get("popup") === "true" && setVisibleLeft(true)
    }, [])

    return (
        <Fragment>
            <Sidebar
                showCloseIcon={false}
                blockScroll={true}
                modal={false}
                icons={() => (
                    <div className="w-full">
                        <WhiteNavbar goBackTo={() => setVisibleLeft(false)} icons="arrow-left" title="My Profile" />
                    </div>
                )}
                className="defaultSidebar"
                visible={visibleLeft}
                onHide={() => setVisibleLeft(false)}>
                <div className="grid grid-rows-[auto,1fr,auto]"
                    style={{ height: "calc(100vh - 56px)" }}
                >
                    <div className='p-4 flex items-center bg-white100'
                    >
                        <Image src={authDetail.photoURL || defaultAvatar}
                            alt="adfa"
                            className="rounded w-16 h-16"
                        />
                        <div className='ml-2'>
                            <p className="font-semibold">{authDetail.displayName}</p>
                            <p className=' text-sm'>{authDetail?.email}</p>
                            <p className=' text-sm'>{authDetail?.phoneNumber}</p>
                        </div>
                    </div>
                    <div className="my-2 bg-white100 p-4">
                        <p className="font-semibold">Your Account</p>
                        <div className=" flex-wrap">
                            {
                                _.map(SideBarMenuLinks, (item: any, idx: number) => {
                                    return (<div key={idx}
                                        onClick={() => navigate(item.url)}
                                        className="p-4 w-full grid grid-cols-[auto,1fr,auto] items-center"
                                    >
                                        
                                        <img src={require("../../assets/icons/"+item.icon+".svg")}/>
                                        <p>&nbsp;{item.text}</p>
                                        <i
                                            className={`pi pi-angle-right`} />
                                    </div>)
                                })
                            }
                        </div>
                    </div>
                    <div className="bg-white100 p-4 grow">
                        <div
                            onClick={() => dispatch(AuthThunk.signOut())}
                            className="p-4 w-full grid grid-cols-[auto,1fr,auto] items-center"
                        >
                            <i className={`pr-4 pi pi-sign-out text-baseColor`} />
                            <p>Logout</p>
                            <i
                                className={`pi pi-angle-right`} />
                        </div>
                    </div>
                </div>
            </Sidebar>
            <i className="pi pi-bars"
                onClick={() => setVisibleLeft(true)}
            ></i>
        </Fragment >
    )
}

export default DefaultSidebar