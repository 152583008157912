import React from 'react'
import { useNavigate } from 'react-router-dom'

type Props={
  goBackTo:string,
}
const BlackNavbar: React.FunctionComponent<Props> = ({goBackTo}) => {
  const navigate=useNavigate()
  return (
    <div className="p-4 text-white bg-darkBaseColor text-white100 flex items-center">
      <i className="pi pi-arrow-left"
        onClick={()=>navigate(goBackTo)}
      />
      <div className="ml-4 flex flex-1 justify-between items-center">
        <p className="text-xl font-semibold">Confirmation</p>
      </div>
    </div>
  )
}

export default BlackNavbar