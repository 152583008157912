import { LineItem } from "./LineItem";
import { ShipmentStatusUpdate } from "./ShipmentStatusUpdate";

export class Shipment {

    _id: string

   
    estimatedDeliveryDate?: Date

  
    statusUpdates: ShipmentStatusUpdate[]


    status?: string


    lineItems?: LineItem[];


    trackingUrl?: string

    constructor(args: {
        id: string,
        estimatedDeliveryDate: Date,
        statusUpdates: ShipmentStatusUpdate[],
        lineItems : LineItem[],
        shipmentStatus?: string
        trackingUrl?: string
    }) {
        this._id = args.id
        this.estimatedDeliveryDate = args.estimatedDeliveryDate
        this.statusUpdates = args.statusUpdates
        this.status = args.shipmentStatus
        this.lineItems = args.lineItems
        this.trackingUrl = args.trackingUrl
    }

}
