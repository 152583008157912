import { gql } from "@apollo/client";

export const EDIT_CUSTOMER_CONTACT_INFORMATION=gql`mutation updateCustomer($updateCustomerInput: UpdateCustomerInput!, $userId: String!){
  updateCustomer(updateCustomerInput: $updateCustomerInput, userId: $userId)
}`

export const UPDATE_BUSINESS_TYPE=gql`mutation updateCustomerBusinessType($updateCustomerBusinessTypeInput: UpdateCustomerBusinessTypeInput!){
  updateCustomerBusinessType(updateCustomerBusinessTypeInput: $updateCustomerBusinessTypeInput)
}`

export const APPLY_COUPON=gql`mutation ApplyOffer ($cartId: String!, $offerId: String!, $useWalletPoints: Boolean!) {
    applyOffer(cartId: $cartId, offerId: $offerId, useWalletPoints: $useWalletPoints) {
        cartId,
        userId
        lineItems {
            merchandise {
                sku
            }
        }
        appliedOffers {
            id
            code
            isApplicable
            isAutomatic
        }
        availableOffers {
            id
            code
            isApplicable
        }
        netTotal {
            amount
        }
        cartDiscounts{
        id,
        amount{
        currencyCode,
        amount},
        discountCode,
        description,
        isAutomatic,
        active
        }
    }
}`
export const CHECKOUT=gql`mutation CreateCheckout(
  $orderId: String!) {
    CreateCheckout (orderId:$orderId){
    _id
  }
}`

export const EDIT_ORDER_ADDRESS=gql`mutation UpdateOrder(
  $updateOrderInput: UpdateOrderInput!, $orderId: String!) {
    updateOrder (
       updateOrderInput: $updateOrderInput,
        orderId: $orderId
    ) {
      _id
      grossShippingCharge{
        amount
      }
      netShippingCharge{
        amount
      }
      orderNumber
      paymentGatewayInfo{
        gatewayName
      }
      status
      customer{
        firstName
        lastName
      }
      lineItems{
        merchandise{
          title
          id,
          price{
            currencyCode
            amount
          }
          mrp{
            currencyCode
            amount
          }
          sku
          variantTitle
        }
        quantity,
        discounts{
          isAutomatic
          amount{
            currencyCode
            amount
          }
          discountCode
          description
        }
      }
      shippingAddress {
        firstLine,
        secondLine,
        city
        district
        pin
        state
        country
    }
      netTotal{
        currencyCode,
        amount
      }
      paidAmount{
            currencyCode
            amount
          }
    selectedPaymentMethod,
      orderDate,
        netPayable{
            amount
        }
        discounts{
            isAutomatic
            amount {
                currencyCode
                amount
            }
            description
            discountCode
        }
      estimatedDeliveryDate,
      referenceNumber,
      shipments{
        estimatedDeliveryDate,
        statusUpdates{
          title,
          date,
          description,
          statusType,
        },
        status,
      }
    }
}`
export const CLEAR_CART=gql`mutation clearCart($cartId: String!){
  clearCart(cartId: $cartId){
    cartId
    customerBusinessType
  }
}`

export const UPDATE_CART_CUSTOMER=gql`mutation updateCustomer( $cartId: String!, $customerId: String!, $useWalletPoints: Boolean) {
  updateCustomerInCart(cartId: $cartId, customerId: $customerId, useWalletPoints: $useWalletPoints){
    userId
    customerBusinessType
    cartId
    createdAt
    serviceAvailable
    updatedAt
    shippingAddress{
      id
      firstLine
      secondLine
      city
      state
      pin
      country
      district
      landMark
      contactPersons{
        fullName
        phoneNumber
        relation
        nickName
      }
    }
    recommendations{
      text
    }
    grossShippingCharge{
      amount
    }
    netShippingCharge{
      amount
    }
    lineItems{
      itemType
      quantity
      estimatedDeliveryDate
      merchandise{
        title
        id
        sku
        mrp{
          currencyCode
          amount
        }
        weight{
          uom
          value
        }
        price{
          currencyCode
          amount
        }
      }
       discounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    }
    cartDiscounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    netTotal{
      amount
    }
    subTotal{
      amount
    }
    estimatedDeliveryDate
    walletPointsInfo {
      redeemableWalletPoints,
      walletUsageText
    }
  }
}`
export const UPDATE_CART_SHIPPING_ADDRESS=gql`mutation updateShippingAddress( $cartId: String!, $shippingAddress: ShippingAddressInput!) {
  updateShippingAddressInCart(cartId: $cartId, shippingAddress: $shippingAddress){
    userId
    customerBusinessType
    cartId
    createdAt
    serviceAvailable
    updatedAt
    shippingAddress{
      id
      firstLine
      secondLine
      city
      state
      pin
      country
      district
      landMark
      contactPersons{
        fullName
        phoneNumber
        relation
        nickName
      }
    }
    recommendations{
      text
    }
    grossShippingCharge{
      amount
    }
    netShippingCharge{
      amount
    }
    lineItems{
      estimatedDeliveryDate
      itemType
      quantity
      merchandise{
        title
        id
        sku
        mrp{
          currencyCode
          amount
        }
        weight{
          uom
          value
        }
        price{
          currencyCode
          amount
        }
      }
       discounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    }
    cartDiscounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    netTotal{
      amount
    }
    subTotal{
      amount
    }
    estimatedDeliveryDate
    walletPointsInfo {
      redeemableWalletPoints,
      walletUsageText
    }
  }
}`
export const UPDATE_CART_LINE_ITEM=gql`mutation updateCartItem( $cartId: String!, $lineItemInput: LineItemInput!) {
  updateCartItem(cartId: $cartId, lineItemInput: $lineItemInput){
    userId
    cartId
    customerBusinessType
    createdAt
    serviceAvailable
    updatedAt
    shippingAddress{
      id
      firstLine
      secondLine
      city
      state
      pin
      country
      district
      landMark
      contactPersons{
        fullName
        phoneNumber
        relation
        nickName
      }
    }
    recommendations{
      text
    }
    grossShippingCharge{
      amount
    }
    netShippingCharge{
      amount
    }
    lineItems{
      estimatedDeliveryDate
      itemType
      quantity
      merchandise{
        title
        id
        sku
        variantTitle
        mrp{
          currencyCode
          amount
        }
        weight{
          uom
          value
        }
        price{
          currencyCode
          amount
        }
      }
       discounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    }
    cartDiscounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    netTotal{
      amount
    }
    subTotal{
      amount
    }
    appliedOffers {
      id
      code
      isApplicable
      isAutomatic
    }
    availableOffers {
      id
      code
      isApplicable
    }
    estimatedDeliveryDate
    walletPointsInfo {
      redeemableWalletPoints,
      walletUsageText
    }
  }
}`

export const CALCULATE_WALLET_POINTS_AND_UPDATE_OFFERS = gql`mutation calculateWalletPointsAndUpdateOffers($cartId: String!, $useWalletPoints: Boolean!) {
  calculateWalletPointsAndUpdateOffers(cartId: $cartId, useWalletPoints: $useWalletPoints) {
    userId
    cartId
    customerBusinessType
    createdAt
    serviceAvailable
    updatedAt
    shippingAddress{
      id
      firstLine
      secondLine
      city
      state
      pin
      country
      district
      landMark
      contactPersons{
        fullName
        phoneNumber
        relation
        nickName
      }
    }
    recommendations{
      text
    }
    grossShippingCharge{
      amount
    }
    netShippingCharge{
      amount
    }
    lineItems{
      estimatedDeliveryDate
      itemType
      quantity
      merchandise{
        title
        id
        sku
        variantTitle
        mrp{
          currencyCode
          amount
        }
        weight{
          uom
          value
        }
        price{
          currencyCode
          amount
        }
      }
       discounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    }
    cartDiscounts{
      discountCode
      amount {
        currencyCode
        amount
      }
      active
      isAutomatic
      description
    }
    netTotal{
      amount
    }
    subTotal{
      amount
    }
    appliedOffers {
      id
      code
      isApplicable
      isAutomatic
    }
    availableOffers {
      id
      code
      isApplicable
    }
    estimatedDeliveryDate
    walletPointsInfo {
      redeemableWalletPoints,
      walletUsageText
    }
  }
}`

export const CHECKOUT_WITH_PAYMENT_LINK = gql`mutation checkOutWithPaymentLink(
  $orderId : String!
){
  CheckoutWithPaymentLink(orderId:$orderId){
    _id
  }
}
`

export const CREATE_CART=gql`mutation createCart{
  createCart{
      cartId
      customerBusinessType
  }
}`


export const REMOVE_COUPON=gql`mutation RemoveOffer($cartId: String!, $offerId: String!, $useWalletPoints: Boolean!) {
    removeOffer(cartId: $cartId, offerId: $offerId, useWalletPoints: $useWalletPoints) {
        cartId,
        appliedOffers{
            id
            code
            isAutomatic
        }
        availableOffers {
            id
            code
        }
        netTotal{
            amount
        }
        cartDiscounts{
        id,
        amount{
        currencyCode,
        amount},
        discountCode,
        description,
        isAutomatic,
        active
        }
    }
}`

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $createCustomerInput: CreateCustomerInput!
    $createAddressInput: CreateAddressInput!
  ) {
    createCustomer(
      createCustomerInput: $createCustomerInput
      createAddressInput: $createAddressInput
    ) {
      userId
      firstName
      lastName
      shippingAddresses {
        id
        firstLine
        secondLine
        landMark
        contactPersons {
          fullName
          phoneNumber
          relation
        }
      }
      phoneNumber
      email
      fathersName
    }
  }
`;
export const CREATE_SHIPPING_ADDRESS = gql`mutation createShippingAddress($createAddressInput: CreateAddressInput!, $userId: String!){
    createShippingAddress(createAddressInput: $createAddressInput, userId: $userId){
    id,
    firstLine,
    secondLine,
    contactPersons{
      fullName,
      phoneNumber,
    },
  }
}`
export const EDIT_SHIPPING_ADDRESS = gql`mutation updateShippingAddress($updateAddressInput: UpdateAddressInput!, $userId: String!){
  updateShippingAddress(updateAddressInput: $updateAddressInput, userId: $userId){
  id,
  firstLine,
  secondLine,
  contactPersons{
    fullName,
    phoneNumber,
  },
}
}`
export const PLACE_ORDER = gql`
mutation CreateOrderInput(
  $CreateOrderCartInput: CreateOrderCartInput!,
  $userId: String!) {
    cartOrder (
        CreateOrderCartInput: $CreateOrderCartInput,
        userId: $userId
    ) {
        _id,
        grossShippingCharge{
          amount
        }
        netShippingCharge{
          amount
        }
        netTotal{
            amount
        },
        paidAmount{
            amount
        },
        netPayable{
            amount
        },
        discounts{
            isAutomatic
            amount {
                currencyCode,
                amount
            },
            description,
            discountCode
        }
        allowedPaymentMethods{
          method
        }
    }
}
`
export const ADD_TRANSACTION = gql`mutation addTransaction($userId: String!, $createTransactionInput: CreateTransactionInput!){
  addTransaction(userId: $userId, createTransactionInput: $createTransactionInput){
    walletId,
    points,
    transactions {
      id
      walletId
      transactionConfig
      transactionType
      points
      referenceDocType
      referenceDocNo
      createdAtDateString
    }
  }
}`

export const CONVERT_ORDER_TO_COD = gql `mutation ConvertOrderToCOD($orderId: String!) {
  ConvertOrderToCOD(orderId: $orderId) {
    _id
    selectedPaymentMethod
    status
  }
}`

export const CREATE_ORDERS = gql`mutation CreateOrders($createOrdersInput: CreateOrderCartInput!, $userId: String!) {
  CreateOrders(createOrdersInput: $createOrdersInput, userId: $userId) {
    _id,
    grossShippingCharge{
      amount
    }
    netShippingCharge{
      amount
    }
    netTotal{
        amount
    },
    paidAmount{
        amount
    },
    netPayable{
        amount
    },
    discounts{
        isAutomatic
        amount {
            currencyCode,
            amount
        },
        description,
        discountCode
    }
    allowedPaymentMethods{
      method
    }
  }
}`

export const CHECKOUT_ORDERS_WITH_COD = gql`mutation CheckoutOrdersWithCOD($orderIds: [String!]!) {
  CheckoutOrdersWithCOD(orderIds: $orderIds) {
    _id
  }
}`