import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import cartSlices from "../global/reduxSlices/cartSlices";
import billSlices from "../global/reduxSlices/billSlice"
import customerSlice from "../global/reduxSlices/customerSlice";
import authSlice from "../global/reduxSlices/authSlice";
import orderSlices from "../global/reduxSlices/orderSlices";
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, } from 'redux-persist';
import { combineReducers } from "redux"
import offerSlices from "../global/reduxSlices/offerSlices";

const persistConfig = {
    key: 'root',
    storage:storage
}
const reducers = combineReducers({
    cart: cartSlices,
    bill: billSlices,
    customer: customerSlice,
    auth: authSlice,
    order: orderSlices,
    offer: offerSlices
});

const persistedReducers = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducers,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck : false,
        immutableCheck : false
    }) 
})

export const persistor = persistStore(store);


// Infer the Rootstate and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>


