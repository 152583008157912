import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONVERT_ORDER_TO_COD } from "../../gqlOperations/mutations";
import { client } from "../../gqlOperations/graphql";
import { GET_ORDER_CONFIRMATION } from "../../gqlOperations/queries";

export const convertOrderToCOD = createAsyncThunk(
  'order/convert_to_cod',
  async (payload: { orderId: string }, { getState }) => {
    try {
      const state: any = getState();
      const response = await client.mutate({
        mutation: CONVERT_ORDER_TO_COD,
        variables: { orderId: payload.orderId },
        fetchPolicy: 'network-only',
      });

      return response.data;
    } catch (error) {
      console.error('Error converting order to COD:', error);
      throw error;
    }
  }
);

export const getOrderSummary = createAsyncThunk(
  "order/getOrderSummary",
  async (payload: { orderId: string }, { getState }) => {
    try {
      const state: any = getState();
      const result = await client.query({
        query: GET_ORDER_CONFIRMATION,
        variables: { orderId: payload.orderId },
        fetchPolicy: "no-cache",
      });
      return result;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
);

export const OrderThunk = {
  convertOrderToCOD,
  getOrderSummary,
};
