import Navbar from "../layouts/Navbar"
import React, { Suspense } from 'react'
import PageNotFound from "../pages/404"
import { useAppSelector } from "../app/hooks"
import intersection from "lodash/intersection"
import ListOfRoutes from "../global/listOfRoutes"
import { Routes, Route, useLocation } from 'react-router-dom'
import DefaultLoader from "../components/loaders/DefaultLoader"
import TokenExpiration from "../components/dialogs/tokenExpiration"

const MainLayout = () => {
    const { authDetail } = useAppSelector(state => state.auth)
    const location = useLocation()

    const { customAttributes } = authDetail || {}
    const { roles = [] } = customAttributes || {}

    const routeRolesPermission = (roles: any, route: any) => {
        return intersection(roles,route).length>0;
    }
    return (
        <React.Fragment>
            <TokenExpiration />
            <div className="flex-col w-screen min-h-screen">
                <Navbar />
                {
                    (!!roles.length)
                    &&
                    <main className={`${["/customerorderhistory","/","/salesperson/orderlisting", "/salesperson/yourcustomers","/referralhistory"].includes(location.pathname) ? "p-0" : "p-4"} flex-1`}>
                        <Suspense fallback={<DefaultLoader />}>
                            {localStorage.getItem("token") &&
                                <Routes>
                                    {ListOfRoutes.map((route: any, index: number) => {
                                        const routePermission = routeRolesPermission(roles, route.roles)
                                        return (
                                            (routePermission && route.element)
                                                ?
                                                <Route
                                                    key={index}
                                                    path={route.path}
                                                    element={<route.element />}
                                                />
                                                :
                                                <Route
                                                    key={index}
                                                    path={route.path}
                                                    element={<PageNotFound />}
                                                />
                                        )
                                    })}
                                </Routes>
                            }
                        </Suspense>
                    </main>
                }
            </div>
        </React.Fragment >
    )
}

export default MainLayout