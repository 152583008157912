/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AuthThunk } from "../../global/reduxThunk/auth"

const TokenExpiration: React.FC = () => {
  const [visible, setVisible] = useState<boolean>(true);
  const dispatch = useAppDispatch();
  const isTokenExp = JSON.parse(localStorage.getItem('isTokenExp') || "false");

  const onLogin = async () => {
    localStorage.removeItem('isTokenExp');
    await dispatch(AuthThunk.signOut());
  }
  const refreshToken = async () => {
    localStorage.removeItem('isTokenExp');
    window.location.reload();
  }

  return (
    <div>
      {/* {isTokenExp
        && */}
        <ConfirmDialog
          accept={refreshToken}
          reject={onLogin}
          // visible={true}
          visible={false}
          // visible={JSON.parse(localStorage.getItem('isTokenExp') || "false")}
          onHide={() => setVisible(false)}
          message="Are you sure you want to proceed?"
          header="Confirmation" icon="pi pi-exclamation-triangle" />
      {/* } */}
    </div>
  )
}

export default TokenExpiration