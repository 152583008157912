
export class PaymentMethod {
   
    method: string

    constructor(args:{
        method: string
    }){
        this.method = args.method
    }
}